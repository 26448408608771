import React from 'react';
import useScreenSize from '../../utils/useScreenSize';

export default function Info() {
  const isMobile = useScreenSize();

  return (
    <section className='info'>
      <h4 className="info__h4">Скидки и акции в лучших заведениях вашего города</h4>
      <p className="info__text info__text_first">
        Скидками пользуется весь мир и они давно воспринимаются людьми как способ разумной экономии. Поэтому Good Day с радостью приблизит к миру, где скидки и акции - выгодно, приятно и удобно. Сэкономить можно на посещении ресторана, салона красоты, медицинского центра, на покупке товаров и билетов на любые развлечения.
      </p>
      <details open={!isMobile}>
        <summary style={{ display: "flex" }}>
          <h5 className="info__h5">Good Day предоставляет акции и скидки</h5>
        </summary>
        <p className="info__text">
          Развлечения: насладиться речными и конными прогулками, сходить в боулинг или на пейнтбол, пройти квесты. <br />
          Рестораны и кафе: романтические ужины и посиделки в баре с друзьями теперь можно организовывать гораздо чаще <br />
          Красота и здоровье: сделать маникюр и педикюр в салонах красоты, УЗИ в клиниках. <br />
          Спорт: спортзалы, а также танцы, йога и многое другое. <br />
          Туры: отдохнуть в Таиланде, Турции, Испании, Греции и других странах без серьезного удара по кошельку, <br />
          Авто: шиномонтаж, химчистка, тонировка стекол и заправка кондиционера для железного друга. <br />
          Купить товары для дома и детей, мебель, бытовую технику и электронику, обувь и одежду. <br />
        </p>
      </details>
      <details open={!isMobile}>
        <summary style={{ display: "flex" }}>
          <h5 className="info__h5">Наши преимущества:</h5>
        </summary>
        <p className="info__text">
          Большой выбор услуг и товаров от известных брендов. <br />
          Скидки до 90%. Good Day работает напрямую с компаниями-партнерами. <br />
          Пользуйтесь уникальными промокодами с помощью удобного мобильного приложения. <br />
          Окунитесь в мир акций, скидок и купонов в вашем городе и платите меньше <br />
        </p>
      </details>
    </section>
  );
}